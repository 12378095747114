.helpModal {
    max-height: 40vh;
    overflow: auto;
    padding: 6px;
}

.helpModalText {
    font-size: 13px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;
}

/* .settingsModal {
    display: flex;
    flex-direction: column;
    border: 1px solid #b0bec5;
    border-radius:4px;
    padding: 10px;
}

.settingsModal p {    
    font-weight: 600;
}

.settingsModal > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.settingsModal > div * {
    font-size: 14px;
}

.settingsModal button {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
} */

.divEnclosurePlayer {
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    animation-name: enclosurePlayerSlideUp;
    animation-duration: .5s;
    animation-timing-function: ease;
}

.enclosurePlayer {
    width: 420px;
    height: 90px;
    padding: 10px 15px 5px 15px;
    border-radius: 30px 30px 0 0;
    box-shadow: 0 0 6px 0 black;
    background-color: white;    
}

@keyframes enclosurePlayerSlideUp {
    0% {
        bottom: -100px;
    }
    100% {
        bottom: 0;
    }
}

.hidePlayerIcon {
    cursor: pointer;
    float:right;
    margin: 6px;
    width: 18px;
    height: 18px;
}

.audioTitle {
    text-align: center;
    margin: 10px 0px 0 0px;
    padding: 0 4px 0 12px;
    font-size: 13px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.enclosureAudio {
    margin: 6px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.audioElement {
    outline: none;
    margin: 0 4px 0 0;
    font-size: 13px;
}

/* @media screen and (max-width: 800px) {
    .enclosurePlayer {
        left: 0;
    }
} */