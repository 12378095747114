.iconButton {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 6px;    
}

.divFeedTitle {
    font-size: 24px;
    font-weight: 600;
    /* padding-top: 15px;
    padding-bottom: 10px */
    margin: 10px 0 10px 0;
}

.controlsImage {
    width: 42px;
    height: 42px;
    border: 1px solid black;
    transition: transform .2s;    
    margin-bottom: 30px;
}

.controlsImage:hover {
    transform: scale(2.0);
}

.controlsImageIcon {
    margin: 0 0 30px 0;
    width: 40px;
    height: 40px;
    color: #fc0505;
}

.controlsButton {
    margin-bottom: 20px;
    margin-left: 0;
    padding: 0;
    background-color: #ffffff;
}

.controlsButtonRight {
    margin: 0 0 0 20px;
    padding: 0;
    background-color: #ffffff;
}

.controlsButtonIcon {
    width: 32px;
    height: 32px;
}

.controlsButtonIconRight {
    width: 20px;
    height: 20px;
}

@media screen and (max-width: 800px) {
    .controlsImage {
        margin: 0 20px 0 0;
    }

    .controlsImageIcon {
        margin: 0 20px 0 0;
    }

    .controlsButton {
        margin: 0 20px 0 0;
    }

    .controlsButtonIcon {
        width: 24px;
        height: 24px;
    }
}