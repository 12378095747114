.iconButton {
    outline: none;
    border: none;
    background:none;
    cursor: pointer;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 12vh;
    background-image: linear-gradient(#e6e6e6, #f2f2f2, #fdfdfd);
    position: sticky;
    top: 0;
    left: 0;
}

.headerCenter {
    display: flex;
}

.headerControls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    margin-left: 30px;
}

.headerControlsButtons {
    display:flex;
    flex-direction: row;
    align-items: center;
}