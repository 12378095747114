.readerApp {    
    margin: auto;
    width: 80vw;
    height: 100vh;
    position: relative;
}

.mainContent {
    font-size: 13px;
    height: 90%;
}

.divMainContentButtons {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 100%;
    position: absolute;
    top: 100px;
    z-index: 0;
}

.contentButtonsFirstRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contentButtonsSecondRow {
    display: flex;
    flex-direction: row;
}

.divMainContentButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    margin-right: 4px;
    width: 110px;
    height: 110px;
    position: relative;
}

.divMainContentButton:hover {
    background-color: #f7f7f7;
    border-radius: 14px;
}

.divMainContentButtonText {
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

@media screen and (max-width: 800px) {
    .readerApp {
        width: 95vw;
    }

    .divMainContentButton {
        padding: 4px;
        margin-right: 2px;
        width: 90px;
        height: 90px;
    }

    .divMainContentButtonText {
        font-size: 12px;
    }
}