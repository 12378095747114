.iconButton {
    outline: none;
    border: none;
    background:none;
    cursor: pointer;
}

.playlistHeaderButton {
    composes: iconButton;
    transition: transform .2s;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 2px;
}

.playlistHeaderButton:hover {
    transform: scale(1.2);
}

.stationItemButton {
    padding: 4px;
    margin-right: 4px;
    margin-top: 0;
    margin-bottom: 0;
}