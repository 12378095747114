.iconButton {
    outline: none;
    border: none;
    background:none;
    cursor: pointer;
}

.playlistAdd {
    margin-right: 10px;
}

.searchResultItemTagList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2px;
}

.searchResultItemTag {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 3px;
    padding-right: 3px;
    margin-top: 1px;
    margin-right: 2px;
    border: 1px solid black;
    border-radius: 3px;
    background-color: #4682b4;
    color: #f4f4f4;
    font-weight: 600;
}

.tagDescriptionText {
    font-size: 11px;
    text-align: left;
    margin-bottom: 2px;
    margin-top: 0;
}

.moreResults {
    text-align: center;
}

.moreResultsButton {
    background-color: #edf3f8;
    padding: 6px;
}