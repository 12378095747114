/* * {    
    background-color: #fbfbfb;
} */

.clickable {
    cursor: pointer;
}

.truncate {
    width: 80vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.iconButton {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 6px;    
}

/* header {
    display:flex;
    justify-content: space-between;
    align-items: center;
}

header div {
    display: flex;
    align-items: center;
    line-height: 10px;
}

header > div:first-child > i {
    color: #ff8000;
    padding-right: 5px;
}

header > .navFeedButtons {
    display:flex;
    flex-direction: row;
    justify-content: space-between;    
}

header > .navFeedButtons button {
    transition: transform .2s;
}

header > .navFeedButtons button:hover {
    transform: scale(1.4);
}

header > .navFeedButtons i {
    font-size: 17px;    
}

header > div > p {
    font-size: 20px;
}

header > .navFeedButtonsRight {
    display: flex;
    align-items: center;
}

header > .navFeedButtonsRight > * {
    margin-left: 6px;
    padding: 4px;
}

header > .navFeedButtonsRight i {
    font-size: 28px;    
}

.helpModal {
    max-height: 40vh;
    overflow: auto;
}

.helpModal p {
    font-size: 13px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 8px;
} */

/* .settingsModal {
    display: flex;
    flex-direction: column;
    border: 1px solid #b0bec5;
    border-radius:4px;
    padding: 10px;
}

.settingsModal p {    
    font-weight: 600;
}

.settingsModal > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.settingsModal > div * {
    font-size: 14px;
}

.settingsModal button {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
} */

/* main {
    font-size: 13px;
} */

/* .divFeedBar {
    border: 2px solid #333333;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    min-width: 100%;
}

.divAdd {
    display: flex;
    align-items: flex-start;
    padding-left: 4px;
    padding-right: 10px;    
    border-right: 2px solid #333333;
}

.divAdd > a {
    cursor: pointer;
    color: #29a3a3;
    transition: transform .2s;
}

.divAdd > a:hover {
    transform: scale(1.5);
}

.divAdd i {
    padding: 10px;
    margin-top: 6px;
    font-size: 18px;
} */

/* .addEditButtonModal {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.addEditButtonModal > * {
    margin: 2px;
    font-size: 13px;
}

.multiFeedModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.multiFeedModal div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 6px;
}

.multiFeedModal label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0px;
}

.multiFeedModal label > p {
    padding: 2px;
    margin: 0;
    text-align: left;
}

.multiFeedModal label > p:last-child {
    font-size: 13px;
}

.multiFeedModal div,
.multiFeedModal label,
.multiFeedModal div p {
    background-color: #F5FCFF;
} */

/* .divAllFeeds {
    display: flex;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
}

.divAllFeeds a {
    cursor: pointer;
    padding: 8px;
    margin-top: 6px;
    border: 2px solid #37474f;
    border-radius: 30px;
    min-width: 80px;
    font-weight: 600;
    transition: transform .2s;
}

.divAllFeeds a:hover {
    background-color: #37474f;
    color: #fbfbfb;
} */

/* .divFeedsWrapperWrapper {
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
    padding-left: 10px;
    border-left: 1px solid #cccccc;
}

.divFeedsWrapperWrapper button {
    margin-top: 8px;
}

.divFeedsWrapper {
    display: flex;
    align-items: start;
    transition: max-height .3s ease-out;
}

.divFeedsHidden {
    overflow-y: hidden;
    max-height: 45px;
}

.divFeedsExpanded {
    overflow-y: visible;
    max-height: 50vh;
}

.divFeeds {
    display: flex;
    align-items: start;
    justify-content: left;
    flex-wrap: wrap;
    z-index: 100;
    width: 100%;
}

.divFeeds a {
    cursor: pointer;
    padding: 8px;
    margin-top: 6px;
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 5px;
    border: 2px solid #37474f;
    border-radius: 30px;
    min-width: 70px;
    transition: transform .2s;
    text-align: center;
    font-weight: 600;
}

.divFeeds a:hover {
    background-color: #37474f;
    color: #fbfbfb;
}

.divFeedsActive {
    background-color: #37474f;
    color: #fbfbfb;
} */

/* .divHelpInfoMain {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.divHelpInfoMain .divHelpInfoButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 8px solid #d9d9d9;
    border-radius: 8px;
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 35px;
    padding-right: 35px;
    color: #d9d9d9;
}

.divHelpInfoMain .divHelpInfoButton:hover {
    border: 8px solid #0044cc;
    color: #0044cc;
}

.divHelpInfoMain .divHelpInfoButton i {
    font-size: 50px;
}

.divHelpInfoMain .divHelpInfoButton p {
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 10px;
} */

/* .divFeedArticlesLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
}

.divFeedArticles {
    width: 100%;
}

.divFeedArticles .divFeedTitle {
    font-size: 24px;
    font-weight: 600;
    padding-top: 15px;
    padding-bottom: 10px
}

.divFeedArticles > section {
    display: flex;
    flex-direction: row;
}

.divFeedArticles > section > div:first-child {
    margin-right: 30px;
}

.divFeedArticles .divFeedArticlesControls {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 2px;
}

.divFeedArticles .divFeedArticlesControls > * {
    display: block;
    margin-bottom: 30px;
}

.divFeedArticles .divFeedArticlesControls > img {
    width: 42px;
    height: 42px;
    border: 1px solid black;
    transition: transform .2s;
}

.divFeedArticles .divFeedArticlesControls > img:hover {
    transform: scale(1.5);
}

.divFeedArticles .divFeedArticlesControls > i {
    font-size: 40px;
    color: #fc0505;    
}

.divFeedArticles .divFeedArticlesControls > a > i {
    font-size: 40px;
} */

/* .divFeedArticles .divFeedArticlesList {
    display: flex;
    flex-direction: column;
    overflow: visible;
    width: 100%;
}

.divFeedArticles article {
    margin-bottom: 2px;
}

article[name="feedArticle"] > button {    
    cursor: pointer;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    border: none;
    border-radius: 5px 5px 0 0;
    outline: none;
    width: 100%;
    line-height: 16px;
    transition: 0.4s;
}

article[name="feedArticle"] > button > div {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

article[name="feedArticle"] > button > div > p {
    text-align: left;
}

article[name="feedArticle"] > button > div div {
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-left: 30px;
}

article[name="feedArticle"] > button > div div p {
    text-align: right;
    white-space: nowrap;
}

article[name="feedArticle"] > button > div i {
    padding-left: 15px;
}

article[name="feedArticle"] > button,
article[name="feedArticle"] > button > div,
article[name="feedArticle"] > button > div p,
article[name="feedArticle"] > button > div div,
article[name="feedArticle"] > button > div i {
    color: #fbfbfb;
    font-size: 14px;
    font-weight: 600;
}

article[name="feedArticle"] section {
    background-color: #fafafa;
    border-radius: 0 0 5px 5px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: auto;
    transition: max-height 0.2s ease-out;
}

article[name="feedArticle"] section > * {
    background-color: #fafafa;
}

article[name="feedArticle"] section > .feedArticleDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 4px;
}

article[name="feedArticle"] section > .feedArticleDetails h3 {
    margin: 0;
}

article[name="feedArticle"] section > .feedArticleDetails button {    
    background-color: #fafafa;
    margin-left: 4px;
}

article[name="feedArticle"] section > .feedArticleDetails i {
    color: #4d4d4d;
    font-size: 20px;
}

article[name="feedArticle"] section .feedArticleAuthor p {
    background-color: #fafafa;
    padding: 0;
    margin-top: 0;
    margin-bottom: 10px;
}

article[name="feedArticle"] section .feedArticleContent > div,
article[name="feedArticle"] section .feedArticleContent > img {
    margin-bottom: 10px;
} */

/* .divViewLink {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divViewLink a > p {
    font-size: 13px;
}

.divViewLink iframe[name="extLinkFrame"] {
    width: 100%;
    height: 80vh;
}

.swalPopup {
    height: 100%;
}

.divEnclosure {
    display: flex;
    flex-direction: column;    
}

.divEnclosure > * {
    padding-bottom: 6px;
}

.divEnclosure > p {
    font-size: 13px;
}

.divEnclosure > a {
    font-size: 13px;
} */