.iconButton {
    outline: none;
    border: none;
    background:none;
    cursor: pointer;
}

.stationSearch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60vw;
}

.searchControlsAdvanced {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border: 2px solid black;
    border-radius: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.searchControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%
}

.controlItem:nth-of-type(1) {flex-grow: 1;}
.controlItem:nth-of-type(2) {
    flex-grow: 3;
    text-align: center;
}

.controlItem:nth-of-type(2) > * {
    margin-right: 2px;
}

.controlItem:nth-of-type(3) {
    flex-grow: 0;
    margin-top: 5px;
}

.searchControlsInput {
    width: 70%;
    height: 40px;
    font-size: 22px;
    border: 1px solid #e9e9e9;
    text-align: center;
}

.searchAdvanced {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.searchAdvancedRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.searchAdvancedItem {
    margin-right: 4px;
    margin-bottom: 4px;
    width: 20%;
}

.searchResults {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    width: 50%;
    /* border: 1px solid black; */
}

.searchResultItems {
    padding-left: 20%;
    padding-right: 8px;
}

.searchResultItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

/* .searchResultItem:hover {
    background-color: #dae6f0;
} */

.searchResultItem > div:nth-child(2) {
    flex: 0 0 auto;
}

.searchResultItemDescription {
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 23vw;
}

.resultItemDescriptionHomepage {    
    margin-bottom: 1px;
}

.descriptionHomepageText {
    font-size: 14px;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.resultItemDescriptionText {
    font-size: 11px;
    text-align: left;
    margin-bottom: 2px;
    margin-top: 0;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 700px) {
    .stationSearch {
        width: 95vw;
    }

    .searchAdvancedRow {
        justify-content: flex-start;
        margin: 0 0 0 20px;
    }

    .searchResults {
        width: 95%;
    }

    .searchResultItems {
        padding: 0;
    }

    .searchResultItem {
        justify-content: flex-start;
    }

    .searchResultItemDescription {
        width: 60vw;
    }
}