.iconButton {
    outline: none;
    border: none;
    background:none;
    cursor: pointer;
}

.headerLeft {
    position: absolute;
    top: 15px;
    left: 20px;
}

.stationImage {
    width: 50px;
    height: 50px;
    object-fit: contain;
    transition: transform .2s;
}

.stationImage:hover {
    transform: scale(1.5);
}

.headerControlsButton {
    composes: iconButton;
    color: #4682b4;
    text-align: center;
    /* font-size: 30px; */
    padding: 4px;
}

/* .headerControlsVolume {
    width: 80%;
} */

.headerSourceLink {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 6px;
}

@media screen and (max-width: 700px) {
    .headerLeft {
        left: 5px;
    }

    .headerSourceLink {
        right: 5px;
    }
}