:root {
    --mainBackgroundColor: #ffffff;
    --feedBarColor: #ffffff;
    --feedButtonColor: #698896;
    --feedButtonTextColor: #fbfbfb;
    --feedButtonHoverColor: #ffffff;
    --feedButtonHoverTextColor: #698896;
}

.iconButton {
    border: none;
    outline: none;
    cursor: pointer;
}

.divFeedBar {
    border: 2px solid #999999;
    border-radius: 5px;
    background-color: var(--feedBarColor);
    padding: 10px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    position: absolute;
    top: 60px;
    z-index: 100;
}

.divAdd {
    display: flex;
    align-items: flex-start;
}

.divAddLink {
    padding: 6px;
    background-color: var(--mainBackgroundColor);
}

.divAllFeeds {
    display: flex;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
}

.divAllFeedsButton {
    cursor: pointer;
    padding: 4px 8px;
    margin: 4px 0 0 0;
    border: 2px solid var(--feedButtonColor);
    border-radius: 30px;
    background-color: var(--feedButtonColor);
    outline: none;
    min-width: 80px;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: var(--feedButtonTextColor);
    transition: transform .2s;
}

.divAllFeedsButton:hover {    
    background-color: var(--feedButtonHoverColor);
    color: var(--feedButtonHoverTextColor);
    border: 2px solid var(--feedButtonColor);
}

.divFeedLinks {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.divFeedLinksButton {
    padding: 6px;
    margin: 4px 4px 0 0;
    background-color: var(--feedBarColor);
    color: #4d4d4d;
    display: none;
}

.divFeedsWrapper {
    display: flex;
    align-items: flex-start;
    scrollbar-width: thin;
    transition: max-height .4s linear -.2s;
    overflow-y: hidden;
    max-height: 35px;
}

.divFeedsWrapper:hover {
    max-height: 70vh;
}

.divFeedLinksButton:focus ~ .divFeedsWrapper {
    max-height: 70vh;
}

.divFeedsHidden {
    overflow-y: hidden;
    max-height: 35px;    
}

.divFeedsExpanded {
    max-height: 70vh;
}

.divFeeds {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex-wrap: wrap;
    width: 100%;
}

.feedLink {
    cursor: pointer;
    padding: 4px 8px;
    margin: 4px 3px 4px 3px;
    border: 2px solid var(--feedButtonColor);
    border-radius: 30px;
    background-color: var(--feedButtonColor);
    outline: none;
    min-width: 60px;
    transition: transform .2s;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: var(--feedButtonTextColor);
}

.feedLink:hover {
    background-color: var(--feedButtonHoverColor);
    color: var(--feedButtonHoverTextColor);
    border: 2px solid var(--feedButtonColor)
}

.divFeedsActive {
    background-color: var(--feedButtonHoverColor);
    color: var(--feedButtonHoverTextColor);
}

@media screen and (max-width: 800px) {
    .divFeedsExpanded {
        overflow-y: auto;
    }    

    .feedLink {
        padding: 4px 4px;
        font-size: 10px;
    }
}

@media only screen and (hover: none) {
    .divFeedLinksButton {
        display: block;
    }
}