.iconButton {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 6px;    
}

.divFeedArticlesLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    position: relative;
    z-index: 0;
    top: 70px;
}

.divFeedArticles {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 0;
    top: 120px;
    left: 0;
    right: 0;
    width: 90%;
    padding: 0 0 100px 0;
    margin: 0 auto 0 auto;
}

.divFeedArticlesEmpty {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    top: 70px;
    width: 90%;
    margin: auto;
    padding: 15px 0 0 0;
}

.feedControlsArticles {
    display: flex;
    flex-direction: row;
}

.divFeedArticlesControls {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 2px;
    margin-right: 30px;
}

.divFeedArticlesControlsEmpty {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.divFeedArticlesList {
    display: flex;
    flex-direction: column;
    overflow: visible;
    width: 100%;
}

.feedArticle {
    margin-bottom: 3px;
}

.headerButtonAll {
    color: #fbfbfb;
    font-size: 13px;
    font-weight: 600;
}

.headerButton {    
    cursor: pointer;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    border: none;
    border-radius: 5px 5px 0 0;
    outline: none;
    width: 100%;
    line-height: 16px;
    transition: 0.4s;
    composes: headerButtonAll;
}

.headerButtonContent {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    composes: headerButtonAll;
}

.headerButtonText {
    text-align: left;
    composes: headerButtonAll;
}

.headerButtonRight {
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-left: 30px;
    composes: headerButtonAll;
}

.headerButtonRightText {
    text-align: right;
    white-space: nowrap;
}

.feedArticleContent {
    background-color: #fafafa;
    border-radius: 0 0 5px 5px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: auto;
    transition: max-height 0.2s ease-out;
}

.feedArticleDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 4px;
    background-color: #fafafa;
}

.feedArticleDetailsButton {    
    background-color: #fafafa;
    margin-left: 4px;
}

.feedArticleAuthor {
    background-color: #fafafa;
    padding: 0;
    margin-top: 0;
    margin-bottom: 10px;
}

.feedArticleImage {
    margin-bottom: 10px;
    max-width: 80%;    
}

@media screen and (max-width: 800px) {
    .divFeedArticles {
        width: 95vw;
        padding: 0 6px 20px 6px
    }
    
    .feedControlsArticles {
        flex-direction: column;
    }

    .divFeedArticlesControls {
        flex-direction: row;
        margin: 0 0 10px 0;
    }
}