.fade-in {
    animation: fadeIn ease 1.6s;
    /* -webkit-animation: fadeIn ease 1.6s;
    -moz-animation: fadeIn ease 1.6s;
    -o-animation: fadeIn ease 1.6s;
    -ms-animation: fadeIn ease 1.6s; */
}
  
@keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

/* @-moz-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
} */

.fade-out {
    animation: fadeOut ease 1.2s;
    -webkit-animation: fadeOut ease 1.2s;
    -moz-animation: fadeOut ease 1.2s;
    -o-animation: fadeOut ease 1.2s;
    -ms-animation: fadeOut ease 1.2s;
}

@keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

/* @-moz-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
} */

.spin {
    animation: spin 2s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

/* .slide-left {
    animation: slideLeft .6s ease;
    -webkit-animation: slideLeft .6s ease;
    -moz-animation: slideLeft .6s ease;
    -o-animation: slideLeft .6s ease;
    -ms-animation: slideLeft .6s ease;
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    10% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.slide-right {
    animation: slideRight .6s ease;
    -webkit-animation: slideRight .6s ease;
    -moz-animation: slideRight .6s ease;
    -o-animation: slideRight .6s ease;
    -ms-animation: slideRight .6s ease;
}

@keyframes slideRight {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }
    10% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
} */