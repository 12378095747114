.iconButton {
  outline: none;
  border: none;
  background:none;
  cursor: pointer;
}

.homePage {
  background-image: url('../images/Code-medium.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;  
}

.topSection {
  
}

.heroImage {
  height: 20vh;
}

.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #14213d; */
  background-color: #1f3360;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  height: 40px;
}

.navBarLeft {
  display: flex;
  justify-content: flex-start;
  padding: 6px 0 10px 20px;
  transform: rotate(-5deg);
}

.nameImageItem {
  padding: 0 6px 0 0;
}

.navBarRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px 0 0;
  height: 100%;
}

.pageLink {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 15px;
  height: 100%;
  padding: 0 12px 0 12px;  
  outline: none;
  border: none;
  background:none;  
  transition: background-color ease 0.4s;
}

.pageLink:hover {
  background-color: #253e74;
}

.pageLinkImage {
  margin: 0 6px 0 0;
}

.pageLink > p {
  margin: 0;
}

.aboutSection {
  height: 75vh;
  background-color: white;
}

.aboutContent {  
  max-width: 750px;
  height: 90%;
  margin: 0 auto 0 auto;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pageLinkText {
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  margin: 0 2px 0 2px;
  text-decoration: underline;
  outline: none;
  border: none;
  background:none;
  cursor: pointer;
}

.header {
  margin: 0 auto 0 auto;
  width: 200px;
}

.headerText {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  padding: 0;
  margin: 0 0 15px 0;
}

.headerUnderline {
  width: 50px;
  height: 3px;
  background-color: #000000;
}

.slide-left {
  animation: slideLeft 1s ease;
}

@keyframes slideLeft {
  0% {
      opacity: 0;
      transform: translateX(50px);
  }
  50% {
      opacity: 1;
  }
  100% {
      /* opacity: 1; */
      transform: translateX(0);
  }
}

.slide-right {
  animation: slideRight 1s ease;
}

@keyframes slideRight {
  0% {
      opacity: 0;
      transform: translateX(-50px);
  }
  50% {
      opacity: 1;
  }
  100% {
      /* opacity: 1; */
      transform: translateX(0);
  }
}

.contentDescription {
  margin: 10px 0 20px 0;
  font-size: 16px;
  line-height: 24px;
}

.divExperienceList {
  margin: 0 0 20px 0;
}

.experienceListHeader {
  margin: 0 0 8px 0;
  color: #19294d;
  font-size: 18px;
  font-weight: 600;
}

.experienceList > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.experienceList > ul > li {
  margin: 0 14px 8px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.experienceBullet {
  width: 14px;
  height: 14px;
  color: #5275c7;
  margin: 0 6px 0 0;
}

.divTechList {
  margin: 0 0 10px 0;
}

.techListHeader {
  margin: 0 0 8px 0;
  color: #19294d;
  font-size: 18px;
  font-weight: 600;
}

.techList {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #ecf0f9;
  border-radius: 6px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.techList > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.aboutFooter {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 10%;
}

.aboutNextSectionButton {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: black;
  margin: 0 0 10px 0;
}

.projectsSection {  
  background-color: #fdc468;
  height: 100vh;  
}

.projectsContent {
  width: 100%;
  height: 90%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projectDescription {

}

.projectText {
  text-align: center;
  margin: 10px 0 8px 0;
  font-size: 16px;
  color: #253e74;
}

.projectTiles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  /* min-height: 30%; */
}

.projectTiles > a {
  outline: none;
  text-decoration: none;
  margin: 20px;
}

.projectTile {
  display: flex;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-repeat: no-repeat;
  width: 310px;
  height: 180px;  
  text-decoration: none;
  position: relative;
  top: 0;
  transition: top ease 0.2s;
}

.projectTile:hover {
  top: -8px;
}

.projectTile > div {
  align-self: flex-end;
  justify-self: center;
}

.projectTileText {
  padding: 6px 10px 6px 10px;
  background-color: #404040;
  color: white;
  font-size: 16px;
}

.projectsFooter {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.projectsNextSectionButton {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin: 0 0 14px 0;
  /* background-color: #262626; */
  color: black;
  align-self: flex-end;
}

.contactSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-bottom: 50px;
  height: 75vh;
  background-color: white;
}

.contactText {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.divContact {
  margin: 20px auto 0 auto;
  max-width: 450px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  /* padding: 15px; */
}

.contactForm > label {
  text-align: left;
  font-weight: 500;
}

.inputField {
  width: 100%;
  padding: 8px;
  margin-bottom: 4px;
  border: 1px solid black;
  border-radius: 0;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
}

.inputField:focus {
  border-radius: 0;
}

.messageText {
  padding: 8px;
  margin-bottom: 10px;
  min-height: 100px;
  border: 1px solid black;
  border-radius: 0;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
}

.divSubmit {
  text-align: right;
}

.submitButton {
  width: 120px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  border: 2px solid #666666;
  background-color: #666666;
  border-radius: 3px;
  font-weight: 600;
  color: white;
  transition: background-color ease 0.4s;
}

.submitButton:hover {
  border: 2px solid #000;
  background-color: #000;  
}

.contactFooter {

}

.footerSection {
  height: 20vh;
  position: relative;
}

.topButton {
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 4px;
  margin: 0 auto;
  background-color: #04c2c9;
  color: white;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
}

@media screen and (max-width: 600px) {  

  .homePage {
    background-image: url('../images/Code-small.jpg');
    background-attachment: scroll;
    background-position: left;
    background-repeat: repeat;
    background-size: unset;
  }

  .heroImage {
    height: 15vh;
  }

  .navBarLeft {
    padding: 6px 0 10px 10px;
  }  
  
  .navBarRight {
    padding: 0 4px 0 0;
  }

  .pageLinks {
    padding: 4px 10px 4px 0;
  }

  .pageLink {
    font-weight: 600;
    font-size: 13px;
    /* margin-left: 2px;     */
    padding: 0 12px 0 12px;
  }

  .pageLink > p {
    display: none;
  }
  
  .pageLinkImage {
    margin: 0;
    width: 18px;
    height: 18px;
  }

  .aboutSection {
    padding: 30px 6px 0 6px;
    height: 100%;
  }

  .experienceList > ul {    
    flex-direction: column;
  }

  .techList {
    margin-bottom: 20px;
  }

  .homePageContent {
    width: 95vw;
  }

  .aboutFooter {
    visibility: hidden;
  }

  .projectsSection {
    height: 100%;
    padding: 40px 6px 0 6px;
  }

  .projectTiles {
    margin-bottom: 20px;
  }

  .projectsNextSectionButton {
    visibility: hidden;
  }

  .contactText, 
  .divContact {
    max-width: 100%;
    padding: 0 6px 0 6px;
  }

  .footerSection {
    height: 10vh;
  }
}

@media only screen and (hover: none) {
  .homePage {
    background-attachment: scroll;
  }
}