.truncate {
    width: 80vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.addEditButtonModal {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.addEditButtonModalItem {
    margin: 2px;
    font-size: 13px;
}

.addEditText {
    font-size: 13px;
    margin: 10px 0 2px 2px;
}

.divViewLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.divViewLinkText {
    font-size: 14px;
    margin: 10px 0 0 0;
}

.divViewLinkFrame {
    width: 100%;
    height: 80vh;
    scrollbar-width: thin;
}

.swalPopup {
    height: 100%;
}

.divEnclosure {
    display: flex;
    flex-direction: column;
}

.enclosureAudio {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.audioElement {
    outline: none;
    margin-right: 10px;
}

.enclosureAudioErrorText {
    font-size: 12px;
    color: #ff6666;
}

