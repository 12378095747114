:root {
    --centerButtonHoverColor: #ececec;
}

.iconButton {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 6px;    
}

.header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
}

.headerTitle {
    display: flex;
    align-items: center;
    line-height: 10px;
}

.headerTitleText {
    font-size: 20px;
}

.headerTitleIcon {
    color: #ff8000;
    padding-right: 5px;
    width: 30px;
    height: 30px;
}

.headerButtonsCenter {
    display:flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    line-height: 10px;    
}

.headerButtonCenter {
    background-color: #ffffff;    
    /* transition: transform .2s; */
}

.headerButtonCenter:hover {
    /* background-color: var(--centerButtonHoverColor);
    border-radius: 4px;     */
}

.headerButtonsRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 10px;
    min-width: 150px;
}

.headerHelpButton {
    margin-top: 4px;
    margin-left: 6px;
    padding: 4px;
    background-color: #ffffff;
}

.headerCodeLink {
    margin-left: 6px;
    padding: 4px;
}

@media screen and (max-width: 800px) {
    .headerButtonsRight {
        min-width: 0;
    }
}